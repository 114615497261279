<template>
  <div>
    <div class="d-flex flex-column">
      <p class="mt-8 mb-0 text-center text-h4 green--text font-weight-bold">
        Transaction complete
      </p>

      <div class="pa-10 pa-sm-3 my-3 d-flex justify-center">
        <v-img v-if="this.$store.state.theme === 'agentpay'"
          max-width="200"
          class="svg-image mt-sm-5"
          src="../../assets/PayAgentBranding/undraw_payment_complete.svg"
          contain
        />
        <v-img v-else
          max-width="200"
          class="svg-image mt-sm-5"
          src="../../assets/CalmonyPayBranding/undraw_payment_complete.svg"
          contain
        />
      </div>

      <div class="details mt-5 mx-auto text-center">
        <div>
          <p class="green--text text--darken-1 font-weight-light mb-1">
            Payment reference:
          </p>
          <p class="green--text text--darken-1 font-weight-bold">
            {{ $store.state.requestID }}
          </p>
        </div>
        <div>
          <p class="green--text text--darken-1 font-weight-light mb-1">
            Amount paid:
          </p>
          <p class="green--text text--darken-1 font-weight-bold">
            £{{ $store.state.paymentDetailsToSubmit.total }}
          </p>
        </div>
      </div>

      <p
        class="
          mt-8
          text-center text-body-1 text-sm-h6
          green--text
          text--darken-2
        "
      >
        On behalf of {{ $store.state.paymentDetails.Company }}, thank you for
        using <span v-if="this.$store.state.theme === 'payagent'">payAgent</span><span v-else>CalmonyPay</span>.
      </p>

      <v-divider class="my-12 mx-12"></v-divider>

      <AdditionalRequests />

      <p class="text-center mt-5 grey--text">
        Would you like to see your agency use
        <a v-if="this.$store.state.theme === 'payagent'" href="https://agentos.com/" target="_blank">payAgent</a><a v-else href="https://calmony.co" target="_blank">CalmonyPay</a>? <br />
        Get in touch today!
      </p>
    </div>
  </div>
</template>

<script>
import AdditionalRequests from "@/components/AdditionalRequests.vue";
import { API } from "aws-amplify";

export default {
  name: "PaymentSuccess",
  components: {
    AdditionalRequests,
  },
  data() {
    return {
      // no data for now
    };
  },
  mounted() {
    this.confirmCardPayment();
  },
  methods: {
    confirmCardPayment() {
      API.get(
        "paymentAppRest",
        `/confirm-card-payment?requestID=${this.$store.state.requestID}`
      ).then((res) => {
        console.log("card payment confirmed", res);
      });
    },
  },
};
</script>

